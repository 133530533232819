
import { mapGetters, mapMutations } from "vuex";
import UiSelect from "~/components/UI/UiSelect.vue";
import { getRedirectUrl } from "@/utils";
import { USER_LOCALE_COOKIE_MAX_AGE } from "~/config";

export default {
  name: "LanguageSwitcher",
  components: { UiSelect },
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
    openTop: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getLanguages: "general/getLanguages",
    }),
  },
  methods: {
    ...mapMutations({
      setSideMenu: "general/SET_SIDE_MENU",
      setMobileMenu: "general/SET_MOBILE_MENU",
    }),
    onSelectLocale(locale) {
      this.setSideMenu(false);
      this.setMobileMenu(false);

      this.$cookies.set("userLocale", locale, {
        path: "/",
        maxAge: USER_LOCALE_COOKIE_MAX_AGE,
      });
      if (this.$i18n.locale === locale) {
        return;
      }

      const newLanguage = this.getLanguages.find((el) => el.slug === locale);
      let url = "";
      if (newLanguage.domain_name && newLanguage.language_redirect) {
        url = getRedirectUrl(
          this.$route.fullPath,
          this.$i18n.localeCodes,
          { userLocale: locale },
          newLanguage.domain_name
        );
      } else {
        url = this.switchLocalePath(locale);
      }

      window.location = url;
    },
  },
};
