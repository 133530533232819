
export default {
  props: {
    placeholder: {
      type: [String, Function],
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
    },
    itemName: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    selectStyle: {
      type: String,
      default: "default",
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    openTop: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterText: "",
      selectedItems: [],
      isOpen: false,
      search: "",
      dirty: false,
    };
  },
  computed: {
    itemToShow() {
      const item = this.items.find((el) => el[this.itemValue] === this.value);
      return item;
    },
    autofillValue: {
      get() {
        return this.itemToShow?.[this.itemName] || "";
      },
      set(val) {
        const item = this.items.find((el) => el.code === val);
        if (item) {
          this.onSelect(item);
        }
      },
    },
    filteredItems() {
      if (this.search && this.dirty) {
        return this.items.filter((el) =>
          el[this.itemName].toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.items;
    },
  },
  watch: {
    isOpen: {
      handler(val) {
        if (!val) {
          this.search = this.itemToShow?.[this.itemName];
        }
        this.dirty = false;
      },
    },
    itemToShow: {
      handler(val) {
        if (val && val[this.itemName]) this.search = val[this.itemName];
      },
      immediate: true,
    },
  },
  methods: {
    onSelect(option) {
      this.$emit("input", option[this.itemValue]);
      this.$emit("change", option[this.itemValue]);
      this.isOpen = false;
    },
    clickOutside() {
      this.isOpen = false;
    },
    async onOpen(val) {
      if (!this.disabled) {
        this.isOpen = val || !this.isOpen;

        await this.$nextTick();
        if (this.isOpen && this.openTop && this.$refs.dropdown) {
          const dropdownEl = this.$refs.dropdown;
          dropdownEl.style.top = -dropdownEl.clientHeight + "px";
        }
      }
    },
  },
};
